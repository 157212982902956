import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import config from '../../config/site';
import { Layout, Container } from 'layouts';
import { Header, SEO, JobList, JobSidebar } from 'components';
import theme from '../../config/theme';
import i18n from '../i18n/i18n';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  @media (max-width: ${theme.breakpoints.s}) {
    display: block;
  }
`;

const Jobs = ({
  data,
  pageContext: {
    locale,
    defaultSlug,
    supportedLangs,
  }
}) => {
  i18n.changeLanguage(locale)

  /**
   * JOBが1件もなくても
   * gatsby-node.jsでページは生成しているので
   * 存在チェックはJobListで実施する
   */
  return (
    <Layout
      locale={locale}
      defaultSlug={defaultSlug}
      supportedLangs={supportedLangs}
    >
      <SEO
        title={`${i18n.t("jobOpenings")} - ${config[locale].title}`}
        desc={i18n.t("jobsPage.description")}
        article
        pathname="/jobs"
      />
      <Header title={i18n.t("jobsPage.title")} />
      <Container type="big">
        <Wrapper>
          <JobList data={data} />
          <JobSidebar data={data} />
        </Wrapper>
      </Container>
    </Layout>
  );
};

export default Jobs;

Jobs.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              path: PropTypes.string.isRequired,
              title: PropTypes.string.isRequired,
              titleJp: PropTypes.string.isRequired,
              employmentType: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
};


/**
 * ロケールでフィルターする
 */
export const query = graphql`
  query($locale: String!) {
    allMarkdownRemark(
      filter: {
        fields: {
          collection: { eq: "jobs" }
          localePath: { eq: $locale }
        }
      }
      sort: { order: ASC, fields: [frontmatter___department, frontmatter___title] }
    ) {
      edges {
        node {
          id
          fields {
            localePath
            slug
            defaultSlug
          }
          frontmatter {
            path
            title
            titleJp
            employmentType
            country
            department
          }
        }
      }
    }
  }
`;
